exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-credits-index-jsx": () => import("./../../../src/pages/credits/index.jsx" /* webpackChunkName: "component---src-pages-credits-index-jsx" */),
  "component---src-pages-download-index-jsx": () => import("./../../../src/pages/download/index.jsx" /* webpackChunkName: "component---src-pages-download-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lang-index-jsx": () => import("./../../../src/pages/lang/index.jsx" /* webpackChunkName: "component---src-pages-lang-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-pages-tags-index-jsx": () => import("./../../../src/pages/tags/index.jsx" /* webpackChunkName: "component---src-pages-tags-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */),
  "component---src-templates-book-index-jsx": () => import("./../../../src/templates/book/index.jsx" /* webpackChunkName: "component---src-templates-book-index-jsx" */),
  "component---src-templates-code-index-jsx": () => import("./../../../src/templates/code/index.jsx" /* webpackChunkName: "component---src-templates-code-index-jsx" */),
  "component---src-templates-csec-index-jsx": () => import("./../../../src/templates/csec/index.jsx" /* webpackChunkName: "component---src-templates-csec-index-jsx" */),
  "component---src-templates-gram-index-jsx": () => import("./../../../src/templates/gram/index.jsx" /* webpackChunkName: "component---src-templates-gram-index-jsx" */),
  "component---src-templates-info-index-jsx": () => import("./../../../src/templates/info/index.jsx" /* webpackChunkName: "component---src-templates-info-index-jsx" */),
  "component---src-templates-lang-index-jsx": () => import("./../../../src/templates/lang/index.jsx" /* webpackChunkName: "component---src-templates-lang-index-jsx" */),
  "component---src-templates-nube-index-jsx": () => import("./../../../src/templates/nube/index.jsx" /* webpackChunkName: "component---src-templates-nube-index-jsx" */),
  "component---src-templates-patt-index-jsx": () => import("./../../../src/templates/patt/index.jsx" /* webpackChunkName: "component---src-templates-patt-index-jsx" */),
  "component---src-templates-post-post-jsx": () => import("./../../../src/templates/post/post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */),
  "component---src-templates-qcom-index-jsx": () => import("./../../../src/templates/qcom/index.jsx" /* webpackChunkName: "component---src-templates-qcom-index-jsx" */),
  "component---src-templates-tags-index-jsx": () => import("./../../../src/templates/tags/index.jsx" /* webpackChunkName: "component---src-templates-tags-index-jsx" */),
  "component---src-templates-tips-index-jsx": () => import("./../../../src/templates/tips/index.jsx" /* webpackChunkName: "component---src-templates-tips-index-jsx" */)
}

